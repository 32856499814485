import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import { PageHeader } from '../components/PageHeader'

import { ReactSVG } from 'react-svg'

import diagramImprovement from '../img/BusinessProcessImprovement.svg'
import diagramInsuranceClaim from '../img/sample-insurance.svg'

export const IndexPageTemplate = ({ image, title, subheading, mainpitch, description }) => (
    <div>
        <PageHeader image={image} title={title} subheading={subheading} />
        <section className="section section--gradient">
            <div className="container">
                <div className="content has-text-centered">
                    <div className="content">
                        <div className="tile">
                            <h3 className="subtitle">{mainpitch.description}</h3>
                        </div>
                    </div>
                    <h2 className="title">{mainpitch.title}</h2>
                    <div className="content">
                        <p className="">{description}</p>
                    </div>
                </div>
            </div>
        </section>
        <section className="hero is-primary">
            <div className="hero-body">
                <div className="container has-text-centered">
                    <h1 className="title">Provide a business process model</h1>
                    <h2 className="subtitle">Step #1</h2>
                    <h2 className="subtitle">
                        <img src={diagramInsuranceClaim} className="bpmn-image" style={{}} />
                    </h2>
                    <p>
                        To improve existing business process, you always start with analyzing the current one. If you do not have an
                        existing process, then you can draw one that does the job. You can always model your business process as a BPMN 2.0
                        model which is required to conduct a simulation. You can use any tool that can generate standard BPMN 2.0 diagram,
                        for example{' '}
                        <a href="https://bpmn.io" target="_blank" style={{ color: 'white' }}>
                            bpmn.io
                        </a>
                        .
                    </p>
                </div>
            </div>
        </section>
        <section className="hero">
            <div className="hero-body">
                <div className="container has-text-centered">
                    <h1 className="title">Provide a scenario to simulate and analyze</h1>
                    <h2 className="subtitle">Step #2</h2>
                    <div className="content">
                        You might already know the bottlenecks and issues from the real world business in some scenarios. However you might
                        not know how the process works in scenarios where there are more cases to simulate, arrival rate is much higher or
                        you suddenly have more or less resources available. Provide the scenario you want to analyze and let the QBP
                        Simulator do its job.
                        <p>It will be quick - you will get the simulation results immediately.</p>
                    </div>
                    <div className="content">
                        <p className="subtitle is-5">Here are some guiding questions to define the scenario:</p>
                        <dl style={{ listStyle: 'none' }}>
                            <li>How many cases to simulate?</li>
                            <li>What is the arrival rate of new cases?</li>
                            <li>Which resources are involved? How many of them? What is their cost?</li>
                            <li>What is the duration (distribution) of each activity?</li>
                            <li>What is the probability to execute a conditional flow?</li>
                        </dl>
                    </div>
                </div>
            </div>
        </section>
        <section className="hero is-primary">
            <div className="hero-body">
                <div className="container has-text-centered">
                    <h1 className="title">Analyze the simulation results</h1>
                    <h2 className="subtitle">Step #3</h2>
                    <h2 className="subtitle">
                        <img src={'/img/sample-results-chart.png'} className="bpmn-image" style={{}} />
                    </h2>
                    <p>
                        The results include resource utilization, costs, waiting times, cycle times and many more key performance
                        indicators. Analyze the results and if you are not satisfied, then make changes in the BPMN model and/or scenario
                        and simulate again. Once you have the business process model that works in all required scenarios, then proceed with
                        implementing the model in the real world and benefit from the changes!
                    </p>
                </div>
            </div>
        </section>
        <section className="hero">
            <div className="hero-body">
                <div className="container has-text-centered">
                    <h1 className="title">Continuos Business Process Management</h1>
                    <h2 className="subtitle">QBP Simulation Engine - the simulator helping you to improve the process</h2>
                    <div className="columns is-centered">
                        <div className="column" />
                        <ReactSVG src={diagramImprovement} className="column is-narrow" style={{ overflow: 'auto' }} />
                        <div className="column" />
                    </div>
                </div>
            </div>
        </section>
        <section className="hero">
            <div className="hero-body">
                <div className="container">
                    <div className="columns">
                        <div className="column is-12 has-text-centered">
                            <div>
                                <Link className="button is-link" to="/simulator/trial?sample=credit_card_application">
                                    Try a sample scenario with BIMP
                                </Link>
                            </div>
                            <br />
                            <Link className="button is-link" to="/products">
                                See our products
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
)

IndexPageTemplate.propTypes = {
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title: PropTypes.string,
    heading: PropTypes.string,
    subheading: PropTypes.string,
    mainpitch: PropTypes.object,
    description: PropTypes.string
}

const IndexPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark

    return (
        <Layout>
            <IndexPageTemplate
                image={frontmatter.image}
                title={frontmatter.title}
                heading={frontmatter.heading}
                subheading={frontmatter.subheading}
                mainpitch={frontmatter.mainpitch}
                description={frontmatter.description}
            />
        </Layout>
    )
}

IndexPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object
        })
    })
}

export default IndexPage

export const pageQuery = graphql`
    query IndexPageTemplate {
        markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
            frontmatter {
                title
                image {
                    childImageSharp {
                        fluid(maxWidth: 2048, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                subheading
                mainpitch {
                    title
                    description
                }
                description
            }
        }
    }
`
