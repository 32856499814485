import * as React from 'react'

import { QBP_BLUE_A50 } from '../const'

interface Props {
    image: any
    title: string
    subheading?: string
}

export class PageHeader extends React.Component<Props> {
    render() {
        const { image, title, subheading } = this.props
        return (
            <div
                className="full-width-image margin-top-0"
                style={{
                    backgroundImage: `url(${!!image.childImageSharp ? image.childImageSharp.fluid.src : image})`,
                    backgroundPosition: 'center'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        height: '150px',
                        lineHeight: '1',
                        justifyContent: 'space-around',
                        alignItems: 'left',
                        flexDirection: 'column'
                    }}
                >
                    <h1
                        className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
                        style={{
                            boxShadow: `${QBP_BLUE_A50} 0.5rem 0px 0px, ${QBP_BLUE_A50} -0.5rem 0px 0px`,
                            backgroundColor: QBP_BLUE_A50,
                            color: 'white',
                            lineHeight: '1',
                            padding: '0.25em'
                        }}
                    >
                        {title}
                    </h1>
                    {subheading && (
                        <h3
                            className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
                            style={{
                                boxShadow: `${QBP_BLUE_A50} 0.5rem 0px 0px, ${QBP_BLUE_A50} -0.5rem 0px 0px`,
                                backgroundColor: QBP_BLUE_A50,
                                color: 'white',
                                lineHeight: '1',
                                padding: '0.25em'
                            }}
                        >
                            {subheading}
                        </h3>
                    )}
                </div>
            </div>
        )
    }
}
